/**
 * -----------------------------------------------
 * const dibawah untuk nge SET store
 * flow SET vuex COMPONENT => ACTION => MUTATION
 * flow GET call mapGetters lalu call fn dari getters
 * -----------------------------------------------
 */
export const changeNameDnsComponent = "componentName";
export const changeContactComponent = "contactComponent";
export const sendDNSforEdit = "dnsEdit";
export const allContactWhois = "contactWhoisAll";

export default {
  state: {
    manage: {
      /**
       * ---------------------------------------------------
       * supaya bisa mengubah component scr dinamis maka dibuat
       * pakai vuex, pernah coba pakai mixin, tp gagal :)
       * ---------------------------------------------------
       */
      dnsComponent: null,

      /**
       * ---------------------------------------------------
       * untuk menampilkan CRUD component contact
       * ---------------------------------------------------
       */
      contactComponent: "ContactInfo",

      /**
       * ---------------------------------------------------
       * get all contact domain supaya ketika action/crud tidak call api terus
       * contactWhoisAll : array(object)
       * ---------------------------------------------------
       */
      contactWhoisAll: {},

      /**
       * ---------------------------------------------------
       * data edit dns karna tidak ada API untuk row
       * jadi kirim data dari dns list ke vuex lalu get di edit
       * flow DNSLIST(send) => VUEX(set) => EDIT(get)
       * ---------------------------------------------------
       */
      dnsEdit: {}
    }
  },

  getters: {
    /**
     * ---------------------------------------------------
     * get component name dns
     * ---------------------------------------------------
     */
    manageDnsComponent(state) {
      return state.manage.dnsComponent;
    },

    /**
     * ---------------------------------------------------
     * get component name contact
     * ---------------------------------------------------
     */
    manageContactComponent(state) {
      return state.manage.contactComponent;
    },

    /**
     * ---------------------------------------------------
     * get all contact by domain for whois
     * ---------------------------------------------------
     */
    getContactWhois(state) {
      return state.manage.contactWhoisAll;
    },

    /**
     * ---------------------------------------------------
     * get data dns management yang akan diedit
     * ---------------------------------------------------
     */
    getDNSedit(state) {
      return state.manage.dnsEdit;
    }
  },

  /**
   * ---------------------------------------------------
   * tangan pertama ketika dikirim data dr client
   * lalu diteruskan ke mutasi
   * @payload : bisa berisi apa saja
   * ---------------------------------------------------
   */
  actions: {
    [changeNameDnsComponent](state, payload) {
      state.commit(changeNameDnsComponent, payload);
    },

    [changeContactComponent](state, payload) {
      state.commit(changeContactComponent, payload);
    },

    /**
     * -------------------------------------------------
     * payload berisi id (domainid)
     * -------------------------------------------------
     */
    [allContactWhois](state, payload) {
      state.commit(allContactWhois, payload);
    },

    /**
     * -------------------------------------------------
     * payload(object) berisi dns yg akan diedit
     * -------------------------------------------------
     */
    [sendDNSforEdit](state, payload) {
      state.commit(sendDNSforEdit, payload);
    }
  },

  /**
   * ---------------------------------------------------
   * mutation merupakan proses manipulasi data yg dikirim
   * dari user untuk merubah data state,
   * sebenrnya mutation optional, bisa lgsg di action
   * ---------------------------------------------------
   */
  mutations: {
    /**
     * ---------------------------------------------------
     * change name component dns
     * ---------------------------------------------------
     */
    [changeNameDnsComponent](state, payload) {
      state.manage.dnsComponent = payload;
    },

    /**
     * ---------------------------------------------------
     * change name component contact
     * ---------------------------------------------------
     */
    [changeContactComponent](state, payload) {
      state.manage.contactComponent = payload;
    },

    /**
     * ---------------------------------------------------
     * call request api get all contact
     * payload (object) all contacts whois
     * ---------------------------------------------------
     */
    [allContactWhois](state, payload) {
      state.manage.contactWhoisAll = payload;
    },

    /**
     * ---------------------------------------------------
     * SET ke state
     * ---------------------------------------------------
     */
    [sendDNSforEdit](state, payload) {
      state.manage.dnsEdit = payload;
    }
  }
};
