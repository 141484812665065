/**
 * --------------------------------------------------
 * init 2021-11-2021
 * untuk keperluan hal hal kecil tentang vuex store
 * --------------------------------------------------
 */
export const LIVECHAT = "Livechat";

export default {
  state: {
    livechat: {
      btnBanner: true,
      chatOpen: false
    }
  },
  getters: {
    configLivechat(state) {
      return state.livechat;
    }
  },
  actions: {
    /**
     * --------------------------------------------------
     * manipulasi bubble livechat
     * @param btnBanner & chatOpen : boolean
     * --------------------------------------------------
     */
    [LIVECHAT](state, params) {
      if (
        typeof params.btnBanner !== undefined ||
        typeof params.chatOpen !== undefined
      ) {
        state.commit(LIVECHAT, params);
      }
    }
  },
  mutations: {
    [LIVECHAT](state, params) {
      state.livechat = Object.assign(state.livechat, params);
    }
  }
};
