/**
 * ---------------------------------------------
 * tentang breadcrumb prefer ke set when mounted
 * ---------------------------------------------
 */
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export const breadcrumb = {
  data() {
    return {
      breadcrumb: {
        product: { title: "Product", route: "product" }
      }
    };
  },
  methods: {
    /**
     * ---------------------------------------------
     * param breadcrumb must be array
     * ---------------------------------------------
     */
    set_breadcrumb(breadcrumb) {
      if (breadcrumb.constructor !== Array) {
        // if param not array
        breadcrumb = [
          { title: "Breadcrumb" },
          { title: "Is Wrong" },
          { title: "Visit me" }
        ];
      }
      this.$store.dispatch(SET_BREADCRUMB, breadcrumb);
    },

    /**
     * ---------------------------------------------
     * prefix breadcrumb domain
     * ---------------------------------------------
     */
    mx_DomainBreadcrumb(arr_breadcrumb = false) {
      let breadcrumb = [];
      breadcrumb.push(this.breadcrumb.product);
      breadcrumb.push({ title: "Domain", route: "product/domain" });

      if (arr_breadcrumb.constructor === Array) {
        for (var i in arr_breadcrumb) {
          breadcrumb.push(arr_breadcrumb[i]);
        }
      }
      this.set_breadcrumb(breadcrumb);
    },

    /**
     * ---------------------------------------------
     * prefix breadcrumb hosting
     * ---------------------------------------------
     */
    mx_breadcrumbHosting(breadCrumb = false) {
      let breadcrumb = [];
      breadcrumb.push(this.breadcrumb.product);
      breadcrumb.push({ title: "Hosting", route: "product/hosting" });

      if (breadCrumb.constructor === Array) {
        for (var i in breadCrumb) {
          breadcrumb.push(breadCrumb[i]);
        }
      }
      this.set_breadcrumb(breadcrumb);
    },

    /**
     * ---------------------------------------------
     * prefix breadcrumb email
     * ---------------------------------------------
     */
    mx_breadcrumbEmail: function(breadCrumb = false) {
      let breadcrumb = [];
      breadcrumb.push(this.breadcrumb.product);
      breadcrumb.push({ title: "Email", route: "product/email" });

      if (breadCrumb.constructor === Array) {
        for (var i in breadCrumb) {
          breadcrumb.push(breadCrumb[i]);
        }
      }
      this.set_breadcrumb(breadcrumb);
    }
  }
};

/**
 * -------------------------------------------
 * handle response httcode request API
 * -------------------------------------------
 */
import { LOGOUT } from "@/store/auth.module";
export const httpCode = {
  methods: {
    /**
     * -------------------------------------------
     * 200 : success
     * 400 : bad request
     * 401 : not authorize / token expired
     * -------------------------------------------
     */
    mx_handleHttpCode(httpCode = 400, routePath = "/") {
      if (httpCode === 400) {
        this.$router.push({ path: routePath });
      }
      if (httpCode === 401) {
        // destroy token & redirect
        console.log("mixin-general :: logout");
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "login" }));
      }
      if (httpCode === 404) {
        this.$router.push({ path: "404" });
      }
    }
  }
};

/**
 * --------------------------------------------
 * handle loading page
 * --------------------------------------------
 */
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/store/htmlclass.module.js";
export const loading = {
  methods: {
    mx_loading(show = true) {
      if (show) {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");
      } else {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
      }
    }
  }
};

/**
 * -------------------------------------------
 * handle URL api endpoint restapi
 * handle reqeust API tercenter
 * 1 CLASS = 4 XHR[GET|POST|PUT|DELETE] (opt)
 * semuaanya sama aja wkk,, experiment cari best practice nya
 * eh jadi gini, ditengah jalan nemu yg baru lagi
 * maapkeund :)
 * -------------------------------------------
 */

import ApiService from "@/common/api.service";
import APIEndpoint from "@/common/config/restapi.config.json";
export const ApiURL = {
  data() {
    return {
      api: APIEndpoint,
      end: {
        domain: APIEndpoint.product.domain,
        email: APIEndpoint.product.email,
        general: APIEndpoint.product.general,
        hosting: APIEndpoint.product.hosting,
        related: APIEndpoint.product.related,
        vps: APIEndpoint.product.vps,
        ssl: APIEndpoint.product.ssl
      },

      /**
       * -----------------------------------------------
       * centrallin ke http sini aja LATEST (210203)
       * ex. req : this.http.get(this.end.email.banner, slug)
       * -----------------------------------------------
       */
      http: {
        get: function(endpoint, slug = false) {
          return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.setIntercept();
            ApiService.get(endpoint, slug)
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        post: function(endpoint, dataObj = false, slug = false) {
          return new Promise((resolve, reject) => {
            if (typeof dataObj === "object") {
              const URL = slug ? endpoint + "/" + slug : endpoint;

              ApiService.setHeader();
              ApiService.post(URL, dataObj)
                .then(res => {
                  resolve(res);
                })
                .catch(error => {
                  reject(error);
                });
            } else {
              reject("<b>Internal Error : </b> data post must be an object");
            }
          });
        },
        put: function() {
          // body...
        },
        delete: function() {
          // body...
        }
      },

      /**
       * -----------------------------------------------
       * request product general
       * -----------------------------------------------
       */
      xhr_product: {
        get: function(endpoint, slug = false) {
          return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(endpoint, slug)
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      },

      /**
       * ------------------------------------------------
       * request api tools /tools
       * ------------------------------------------------
       */
      xhr_tools: {
        get: function(endpoint, slug = false) {
          return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(endpoint, slug)
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      },

      /**
       * ------------------------------------------------
       * request api invoice
       * ------------------------------------------------
       */
      xhr_invoice: {
        get: function(endpoint, slug = false) {
          return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(APIEndpoint.invoice[endpoint], slug)
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      },

      xhr_user: {
        post: function(endpoint, dataObj = false, slug = false) {
          return new Promise((resolve, reject) => {
            if (typeof dataObj === "object") {
              const path = APIEndpoint.user[endpoint];
              const URL = slug ? path + "/" + slug : path;

              ApiService.setHeader();
              ApiService.post(URL, dataObj)
                .then(res => {
                  resolve(res);
                })
                .catch(error => {
                  reject(error);
                });
            } else {
              reject("<b>Internal Error : </b> data post must be an object");
            }
          });
        },
        get: function(endpoint, slug = false) {
          return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(APIEndpoint.user[endpoint], slug)
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        delete: function(endpoint, dataObj, slug = false) {
          return new Promise((resolve, reject) => {
            if (typeof dataObj === "object") {
              const path = APIEndpoint.user[endpoint];
              const URL = slug ? path + "/" + slug : path;

              ApiService.setHeader();
              ApiService.delete(URL, dataObj)
                .then(data => {
                  resolve(data);
                })
                .catch(error => {
                  reject(error);
                });
            } else {
              reject("<b>Internal Error : </b> data must be an object");
            }
          });
        },
        put: function(endpoint, dataObj, slug = false) {
          return new Promise((resolve, reject) => {
            if (typeof dataObj === "object") {
              const path = APIEndpoint.user[endpoint];
              const URL = slug ? path + "/" + slug : path;

              ApiService.setHeader();
              ApiService.put(URL, dataObj)
                .then(data => {
                  resolve(data);
                })
                .catch(error => {
                  reject(error);
                });
            } else {
              reject("<b>Internal Error : </b> data post must be an object");
            }
          });
        }
      }
    };
  },
  methods: {
    /**
     * ------------------------------------------------
     * [GET] /dashboard
     * ------------------------------------------------
     */
    xhr_dashboard(endpoint, slug = false) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(this.api.dashboard[endpoint], slug)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /**
     * ------------------------------------------------
     * [GET] /product/domain
     * ------------------------------------------------
     */
    xhr_domain(endpoint, slug = false) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(this.api.product.domain[endpoint], slug)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /**
     * ------------------------------------------------
     * [POST] /product/domain
     * ------------------------------------------------
     */
    xhr_domain_post(endpoint, dataObj = false, DomainID = false) {
      return new Promise((resolve, reject) => {
        if (typeof dataObj === "object") {
          const path = this.api.product.domain[endpoint];
          const URL = DomainID ? path + "/" + DomainID : path;

          ApiService.setHeader();
          ApiService.post(URL, dataObj)
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject("<b>Internal Error : </b> data post must be an object");
        }
      });
    },

    /**
     * ------------------------------------------------
     * [PUT] /product/domain
     * ------------------------------------------------
     */
    xhr_domain_put(endpoint, dataObj, DomainID = false) {
      return new Promise((resolve, reject) => {
        if (typeof dataObj === "object") {
          const path = this.api.product.domain[endpoint];
          const URL = DomainID ? path + "/" + DomainID : path;

          ApiService.setHeader();
          ApiService.put(URL, dataObj)
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject("<b>Internal Error : </b> data post must be an object");
        }
      });
    },

    /**
     * ------------------------------------------------
     * [DELETE] /product/domain
     * ------------------------------------------------
     */
    xhr_domain_delete(endpoint, dataObj, DomainID = false) {
      return new Promise((resolve, reject) => {
        if (typeof dataObj === "object") {
          const path = this.api.product.domain[endpoint];
          const URL = DomainID ? path + "/" + DomainID : path;

          ApiService.setHeader();
          ApiService.delete(URL, dataObj)
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject("<b>Internal Error : </b> data must be an object");
        }
      });
    },

    /**
     * ------------------------------------------------
     * [GET] /product/hosting
     * ------------------------------------------------
     */
    xhr_hosting(endpoint, slug = false) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(this.api.product.hosting[endpoint], slug)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /**
     * ------------------------------------------------
     * [POST] /product/hosting
     * ------------------------------------------------
     */
    xhr_hosting_post(endpoint, dataObj = false, HostingID = false) {
      return new Promise((resolve, reject) => {
        if (typeof dataObj === "object") {
          const path = this.api.product.hosting[endpoint];
          const URL = HostingID ? path + "/" + HostingID : path;

          ApiService.setHeader();
          ApiService.post(URL, dataObj)
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject("<b>Internal Error : </b> data post must be an object");
        }
      });
    },

    /**
     * ------------------------------------------------
     * [GET] /news
     * ------------------------------------------------
     */
    xhr_news(endpoint, slug = false) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(this.api.news[endpoint], slug)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /**
     * ------------------------------------------------
     * [GET] /payment
     * ------------------------------------------------
     */
    xhr_payment(endpoint, slug = false) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(this.api.payment[endpoint], slug)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /**
     * ------------------------------------------------
     * [GET] /product/related
     * ------------------------------------------------
     */
    xhr_related(endpoint, slug = false) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(this.api.product.related[endpoint], slug)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /**
     * ------------------------------------------------
     * [GET] class /tools/redirect
     * ------------------------------------------------
     */
    xhr_redirect(endpoint, slug = false) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(APIEndpoint.tools.redirect[endpoint], slug)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

/**
 * -------------------------------------------
 * handle notif sweetalert
 * -------------------------------------------
 */
import Swal from "sweetalert2";
export const notif = {
  methods: {
    /**
     * --------------------------------------------------------
     * notif swal biasa
     * --------------------------------------------------------
     */
    mx_swal(message = "", title = "ERROR", icon = "error") {
      Swal.fire({ title: title, html: message, icon: icon });
    },

    /**
     * --------------------------------------------------------
     * notif swal konfirmasi
     * --------------------------------------------------------
     */
    mx_swal_confirm(message = "", title = "", icon = "question") {
      return new Promise(resolve => {
        Swal.fire({
          icon: icon,
          html: message,
          title: title,
          showDenyButton: false,
          showCancelButton: true
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            resolve(true);
          }
        });
      });
    },

    /**
     * --------------------------------------------------------
     * toast boostra
     * --------------------------------------------------------
     */
    mx_toast: function(body = "", title = "", option = {}) {
      let configDefault = {
        title: title,
        solid: true,
        variant: "success",
        autoHideDelay: 3000,
        toaster: "b-toaster-top-right"
      };

      let config = Object.assign(configDefault, option);
      this.$bvToast.toast(body, config);
    }
  }
};

/**
 * ------------------------------------------
 * utiliti not spesifik for what / just helper
 * ------------------------------------------
 */
import generalConfig from "@/common/config/general.config.json";
import copy from "clipboard-copy";
import { saveAs } from "file-saver";
export const util = {
  data() {
    return {
      mx_config: generalConfig
    };
  },
  methods: {
    /**
     * ------------------------------------------
     * konversi JSON => QUERY PARAM url with ES6
     * ref: https://howchoo.com/javascript/how-to-turn-an-object-into-query-string-parameters-in-javascript
     * params = params (object), prefix(? / menggunakan prefix nggak)
     * ------------------------------------------
     */
    mx_ObjectToQuery(params, prefix = "") {
      if (typeof params === "object") {
        // metode ES6
        let queryString = Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
        return prefix + queryString;
      }
    },

    /**
     * ------------------------------------------
     * params url :
     * internal   | url     | new_tab
     * -----------------------------
     * true       | object  | -
     * false      | URL     | boolean
     * ------------------------------------------
     */
    mx_redirect(url, internal = true, new_tab = true) {
      if (internal) {
        this.$router.push(url);
      } else {
        if (new_tab) {
          window.open(url, "_blank");
        } else {
          location = url;
        }
      }
    },

    /**
     * --------------------------------------------------
     * untuk konversi name recurring to month / year
     * please convert to lower before call this method
     * --------------------------------------------------
     */
    mx_recurring_to_number(recurring) {
      let text = recurring;

      switch (recurring) {
        case "Annually":
          text = "1 Tahun";
          break;

        case "Biennially":
          text = " 2 Tahun";
          break;

        case "Triennially":
          text = "3 Tahun";
          break;
      }

      return text;
    },

    /**
     * --------------------------------------------------
     * get badge status request for SSL
     * @param status string ex.
     * awaiting configruation, pending validation
     * and issued
     * --------------------------------------------------
     */
    mx_badge_ssl_status_request(status) {
      let color = "danger";
      if (status === "pending validation") {
        color = "info";
      } else if (status === "issued") {
        color = "success";
      }

      status = status.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      return `<span class="badge badge-${color}">${status}</span>`;
    },

    /**
     * --------------------------------------------------
     * copy plain text to clipboard
     * --------------------------------------------------
     */
    mx_copy_text(text) {
      copy(text);
    },

    /**
     * --------------------------------------------------
     * for download string to text
     * @param content String : isi dari txt nya
     * @param fileName Sting : nama dari file
     * --------------------------------------------------
     */
    mx_download_txt: function(content = "Rumahweb", fileName = "rumahweb") {
      let file = new File([content], `${fileName}.txt`, {
        type: "text/plain;charset=utf-8"
      });
      saveAs(file);
    }
  }
};
