import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const view = {
  product: "views/pages/product"
};

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/dashboard/dashboard.vue")
        },
        {
          path: "/afiliasi",
          name: "rw-afiliasi",
          component: () => import("@/views/pages/afiliasi/base.vue"),
          children: [
            {
              path: "home",
              name: "afiliasiHome",
              component: () => import("@/views/pages/afiliasi/Home.vue")
            },
            {
              path: "link",
              name: "afiliasiLink",
              component: () => import("@/views/pages/afiliasi/Link.vue")
            },
            {
              path: "profile",
              name: "afiliasiProfile",
              component: () => import("@/views/pages/afiliasi/Profile.vue")
            },
            {
              path: "transaksi",
              name: "afiliasiTransaksi",
              component: () => import("@/views/pages/afiliasi/Transaksi.vue")
            },
            {
              path: "pembayaran",
              name: "afiliasiPembayaran",
              component: () => import("@/views/pages/afiliasi/Pembayaran.vue")
            },
            {
              path: "peraturan",
              name: "afiliasiPeraturan",
              component: () => import("@/views/pages/afiliasi/Peraturan.vue")
            }
          ]
        },
        {
          path: "/product",
          component: () => import(`@/${view.product}/base.vue`),
          children: [
            {
              path: "/",
              name: "product",
              component: () => import(`@/${view.product}/all.vue`)
            },
            {
              path: "detail-default",
              name: "productDetailDefault",
              component: () =>
                import(`@/${view.product}/details/detail-default.vue`)
            },
            {
              path: "detail-flockmail",
              name: "productDetailFlockmail",
              component: () =>
                import(`@/${view.product}/details/detail-flockmail.vue`)
            },

            /**
             * ---------------------------------------------------------
             * home base product domain
             * ---------------------------------------------------------
             */
            {
              path: "domain",
              name: "productDomain",
              component: () =>
                import(`@/${view.product}/domain/product-domain.vue`)
            },

            /**
             * ---------------------------------------------------------
             * get detail domain by id
             * ---------------------------------------------------------
             */
            {
              path: "domain/:id",
              name: "productDomainSingle",
              props: true,
              component: () =>
                import(`@/${view.product}/domain/product-domain-single.vue`)
            },

            /**
             * ---------------------------------------------------------
             * /product/domain/{id}/[menu] - manage domain
             * ---------------------------------------------------------
             */
            {
              path: "domain/:id/:menu",
              name: "productDomainManage",
              props: true,
              component: () =>
                import(
                  `@/${view.product}/domain/management/base-management.vue`
                )
            },

            /**
             * ---------------------------------------------------------
             * base home product hosting
             * ---------------------------------------------------------
             */
            {
              path: "hosting",
              component: () =>
                import(`@/${view.product}/hosting/hosting-base.vue`),
              children: [
                {
                  path: "/",
                  name: "productHosting",
                  component: () =>
                    import(`@/${view.product}/hosting/product-hosting.vue`)
                },
                {
                  path: ":id",
                  name: "productHostingSingle",
                  props: true,
                  component: () =>
                    import(
                      `@/${view.product}/hosting/product-hosting-single.vue`
                    )
                },
                {
                  path: ":id/upgrade",
                  name: "hosting-upgrade-base",
                  component: () =>
                    import(
                      `@/${view.product}/hosting/upgrade/upgrade-base.vue`
                    ),
                  children: [
                    {
                      path: ":addons",
                      name: "hosting-upgrade",
                      props: true,
                      component: () =>
                        import(
                          `@/${view.product}/hosting/upgrade/upgrade-addons.vue`
                        )
                    }
                  ]
                }
              ]
            },

            /**
             * ---------------------------------------------------------
             * product emails
             * :vendor ex. alimail, titan, gapps
             * ---------------------------------------------------------
             */
            {
              path: "email",
              component: () =>
                import(`@/${view.product}/emails/product-email-base.vue`),
              children: [
                {
                  path: "/",
                  name: "rw-email-base",
                  component: () =>
                    import(`@/${view.product}/emails/product-email-list.vue`)
                },
                {
                  path: ":id",
                  name: "rw-email-single",
                  props: true,
                  component: () =>
                    import(`@/${view.product}/emails/product-email-single.vue`)
                }
              ]
            },

            /**
             * --------------------------------------------------
             * product vps
             * --------------------------------------------------
             */
            {
              path: "vps",
              component: () => import(`@/${view.product}/vps/vps-base.vue`),
              children: [
                {
                  path: "/",
                  name: "vps-list",
                  component: () => import(`@/${view.product}/vps/vps-list`)
                },
                {
                  path: ":id",
                  name: "rw-vps-single",
                  props: true,
                  component: () =>
                    import(`@/${view.product}/vps/vps-single.vue`)
                }
              ]
            },

            /**
             * --------------------------------------------------
             * route for ssl product
             * --------------------------------------------------
             */
            {
              path: "ssl",
              component: () => import(`@/${view.product}/ssl/ssl-base`),
              children: [
                {
                  path: "/",
                  name: "ssl-list",
                  component: () => import(`@/${view.product}/ssl/ssl-list`)
                },
                {
                  path: ":id",
                  name: "ssl-single",
                  props: true,
                  component: () => import(`@/${view.product}/ssl/ssl-single`)
                },
                {
                  path: ":id/manage",
                  name: "ssl-manage",
                  props: true,
                  component: () =>
                    import(`@/${view.product}/ssl/manage/ssl-manage-base`)
                  // children: [
                  //   {
                  //     path: ":vendor",
                  //     name: "ssl-manage-base"
                  //   }
                  // ]
                }
              ]
            },
            /**
             * --------------------------------------------------
             * route for lightsail product
             * --------------------------------------------------
             */
            {
              path: "lightsail",
              component: () =>
                import(`@/${view.product}/lightsail/ls-base.vue`),
              children: [
                {
                  path: "/",
                  name: "ls-list",
                  component: () =>
                    import(`@/${view.product}/lightsail/ls-list.vue`)
                },
                {
                  path: ":id",
                  props: true,
                  component: () =>
                    import(`@/${view.product}/lightsail/ls-base-dashboard.vue`),
                  children: [
                    {
                      path: "/",
                      name: "ls-dashboard",
                      redirect: { name: "ls-connect" }
                    },
                    {
                      path: "connect-ssh",
                      name: "ls-connect",
                      component: () =>
                        import(`@/${view.product}/lightsail/manage/connect.vue`)
                    },
                    {
                      path: "metric",
                      name: "ls-metric",
                      component: () =>
                        import(`@/${view.product}/lightsail/manage/metric.vue`)
                    },
                    {
                      path: "ssh-keys",
                      name: "ls-ssh-key",
                      component: () =>
                        import(`@/${view.product}/lightsail/manage/ssh.vue`)
                    },
                    {
                      path: "firewall",
                      name: "ls-firewall",
                      component: () =>
                        import(
                          `@/${view.product}/lightsail/manage/firewall.vue`
                        )
                    },
                    {
                      path: "my-billing",
                      name: "ls-billing",
                      component: () =>
                        import(`@/${view.product}/lightsail/manage/billing.vue`)
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: "/support",
          name: "rw-support",
          component: () => import("@/views/pages/support/base-support.vue"),
          children: [
            {
              path: "live",
              name: "supportLive",
              component: () => import("@/views/pages/support/support-live.vue")
            },
            {
              path: "ticket",
              name: "supportTicket",
              component: () =>
                import("@/views/pages/support/support-ticket.vue")
            }
          ]
        },
        {
          path: "/profile",
          component: () => import("@/views/pages/profile/profile-base.vue"),
          children: [
            {
              path: "/",
              name: "rw-profile",
              component: () => import("@/views/pages/profile/profile-menu.vue")
            },
            {
              path: "sub-account",
              name: "rw-profile-subaccount",
              component: () =>
                import("@/views/pages/profile/sub-akun/sub-base.vue")
            }
          ]
        },
        {
          path: "/invoice",
          component: () => import("@/views/pages/invoice/base.vue"),
          children: [
            {
              path: "/",
              name: "invoiceAll",
              component: () => import("@/views/pages/invoice/invoice-all.vue")
            },
            {
              path: ":id",
              name: "invoiceRow",
              props: true,
              component: () =>
                import("@/views/pages/invoice/invoice-single.vue")
            }
          ]
        },
        {
          path: "/news",
          component: () => import("@/views/pages/news/base_news.vue"),
          children: [
            {
              path: "/",
              name: "news-all",
              component: () => import("@/views/pages/news/news-all.vue")
            },
            {
              path: ":id",
              name: "news-single",
              props: true,
              component: () => import("@/views/pages/news/news-single.vue")
            }
          ]
        },
        {
          path: "/redirect/:menu/:id",
          name: "rw-redirect",
          props: true,
          component: () => import("@/views/pages/redirect/redirect-base.vue")
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/views/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/views/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/views/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/views/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/views/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/auth-base"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/register")
        },
        {
          name: "forgot",
          path: "/forgot",
          component: () => import("@/views/pages/auth/forgot-password")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ],
  mode: "history"
});
