import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";
// import { LOGOUT } from "@/store/auth.module";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.withCredentials = true;
  },

  /**
   * ------------------------------------------------------------
   * Set the default HTTP request headers
   * ------------------------------------------------------------
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  /**
   * --------------------------------------------------
   * https://github.com/axios/axios#interceptors
   * intercept merupakan interup / inject proses return
   * sukses return obj {code:'', message:'', data:''}
   * error return {message : 'xx'}
   * --------------------------------------------------
   */
  setIntercept() {
    Vue.axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (typeof error.response !== "undefined") {
          return Promise.reject(error.response.data);
        }
        return Promise.reject({ message: error.message });
      }
    );
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[rw] ApiService ${error}`);
    });
  },

  /**
   * ------------------------------------------------------------
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   * ------------------------------------------------------------
   */
  get(resource, slug = false) {
    if (!slug) {
      return Vue.axios.get(`${resource}`);
    } else {
      // jika slug isinya get
      if (slug.indexOf("?") > -1 || slug.indexOf("&") > -1) {
        return Vue.axios.get(`${resource}${slug}`);
      } else {
        return Vue.axios.get(`${resource}/${slug}`);
      }
    }
  },

  /**
   * ------------------------------------------------------------
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   * ------------------------------------------------------------
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * ------------------------------------------------------------
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   * ------------------------------------------------------------
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * ------------------------------------------------------------
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   * ------------------------------------------------------------
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * ------------------------------------------------------------
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   * ------------------------------------------------------------
   */
  delete(resource, body) {
    return Vue.axios.delete(resource, { data: body });
  }
};

export default ApiService;
