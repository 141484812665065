/**
 * -----------------------------------
 * khususon untuk profile
 * -----------------------------------
 */

export const GET_SUM_PRODUCT = "getSumProduct";
export const SET_PROFIL = "setProfile";

import { ApiURL } from "@/common/mixins/general.js";
import ApiService from "@/common/api.service";
export default {
  mixins: [ApiURL],
  state: {
    client: {},

    /**
     * --------------------------------------------
     * data jumlah product ex. domain, hosting, etc
     * digunakan di sidenav
     * --------------------------------------------
     */
    sumProduct: {}
  },
  getters: {
    user(state) {
      return state.client;
    },
    get_sumProduct(state) {
      return state.sumProduct;
    }
  },
  actions: {
    /**
     * --------------------------------------------
     * payload : { url : endPoint API profile }
     * --------------------------------------------
     */
    [SET_PROFIL](state, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(payload.url)
          .then(({ data }) => {
            state.commit(SET_PROFIL, data.data.profile);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },

    /**
     * --------------------------------------------
     * payload :  { url : endPoint API sum product }
     * --------------------------------------------
     */
    [GET_SUM_PRODUCT](state, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(payload.url)
          .then(({ data }) => {
            state.commit(GET_SUM_PRODUCT, data.data.sum);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },

  mutations: {
    /**
     * --------------------------------------------
     * request profile user
     * payload : {}
     * --------------------------------------------
     */
    [SET_PROFIL](state, payload) {
      state.client = payload;
    },

    /**
     * --------------------------------------------
     * payload : respons data sum api req
     * --------------------------------------------
     */
    [GET_SUM_PRODUCT](state, payload) {
      state.sumProduct = payload;
    }
  }
};
