import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
import { VERIFY_AUTH } from "./store/auth.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";

// override css global custom by addin
import "./assets/rw/css/rw_general.css";

// API service init
ApiService.init();

/**
 * ------------------------------------------------
 * Ensure we checked auth before each page load.
 * ------------------------------------------------
 */
router.beforeEach(async (to, from, next) => {
  let isAuthenticated = store.getters.isAuthenticated;
  if (to.name !== "login" && !isAuthenticated) {
    if (to.name === "register" || to.name === "forgot") {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
