import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

/**
 * ---------------------------------------------
 * custom store addin
 * profile : untuk profile customer
 * productDomain : untuk handle product domain
 * util : untuk handle hal vuex kecil ex. chat
 * ---------------------------------------------
 */
import profile from "./rw/profile.module.js";
import productDomain from "./rw/product.domain.module.js";
import util from "./rw/util.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    productDomain,
    profile,
    util
  }
});
