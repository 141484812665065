// base API
export const API_URL = "http://restapi.rwreborn.localhost/";
// export const API_URL = "https://rest-reborn.demo4.srs-x.net/";

export const reCaptcha = {
  v3: {
    site_key: "6LfpoSgdAAAAAJ6wOUJ-MSm7BgfhY-5NovEr-4PM"
  }
};

/**
 * --------------------------------------------------
 * list web server for SSL
 * --------------------------------------------------
 */
export const web_server = [
  { value: "1", text: "OL" },
  { value: "2", text: "Apache/ModSSL" },
  { value: "3", text: "Apache-SSL (Ben-SSL, not Stronghold)" },
  { value: "4", text: "C2Net Stronghold" },
  { value: "5", text: "Cobalt Raq" },
  { value: "6", text: "Covalent Server Software" },
  { value: "7", text: "IBM HTTP Server" },
  { value: "8", text: "IBM Internet Connection Server" },
  { value: "9", text: "iPlanet" },
  { value: "10", text: "Java Web Server (Javasoft / Sun)" },
  { value: "11", text: "Lotus Domino" },
  { value: "12", text: "Lotus Domino Go!" },
  { value: "13", text: "Microsoft IIS 1.x to 4.x" },
  { value: "14", text: "Microsoft IIS 5.x to 6.x" },
  { value: "15", text: "Netscape Enterprise Server" },
  { value: "16", text: "Netscape FastTrack" },
  { value: "17", text: "Novell Web Server" },
  { value: "18", text: "Oracle" },
  { value: "19", text: "Quid Pro Quo" },
  { value: "20", text: "R3 SSL Server" },
  { value: "21", text: "Raven SSL" },
  { value: "22", text: "RedHat Linux" },
  { value: "23", text: "SAP Web Application Server" },
  { value: "24", text: "Tomcat" },
  { value: "25", text: "Website Professional" },
  { value: "26", text: "WebStar 4.x and later" },
  { value: "27", text: "WebTen (from Tenon)" },
  { value: "28", text: "Zeus Web Server" },
  { value: "29", text: "Ensim" },
  { value: "30", text: "Plesk" },
  { value: "31", text: "WHM/cPanel" },
  { value: "32", text: "H-Sphere" },
  { value: "33", text: "Cisco 3000 Series VPN Concentrator" },
  { value: "34", text: "Citrix" },
  { value: "35", text: "Microsoft IIS 7.x and later" },
  { value: "71", text: "WebtoB" },
  { value: "72", text: "NginX" }
];

/**
 * --------------------------------------------------
 * List country for SSL or another for get dialCode
 * --------------------------------------------------
 */
export const country = [
  { value: "93", code: "AF", text: "Afghanistan" },
  { value: "355", code: "AL", text: "Albania" },
  { value: "213", code: "DZ", text: "Algeria" },
  { value: "376", code: "AD", text: "Andorra" },
  { value: "244", code: "AO", text: "Angola" },
  { value: "672", code: "AQ", text: "Antarctica" },
  { value: "54", code: "AR", text: "Argentina" },
  { value: "374", code: "AM", text: "Armenia" },
  { value: "297", code: "AW", text: "Aruba" },
  { value: "61", code: "AU", text: "Australia" },
  { value: "43", code: "AT", text: "Austria" },
  { value: "994", code: "AZ", text: "Azerbaijan" },
  { value: "973", code: "BH", text: "Bahrain" },
  { value: "880", code: "BD", text: "Bangladesh" },
  { value: "375", code: "BY", text: "Belarus" },
  { value: "32", code: "BE", text: "Belgium" },
  { value: "501", code: "BZ", text: "Belize" },
  { value: "229", code: "BJ", text: "Benin" },
  { value: "975", code: "BT", text: "Bhutan" },
  { value: "591", code: "BO", text: "Bolivia" },
  { value: "387", code: "BA", text: "Bosnia And Herzegovina" },
  { value: "267", code: "BW", text: "Botswana" },
  { value: "55", code: "BR", text: "Brazil" },
  { value: "246", code: "IO", text: "British Indian Ocean Territory" },
  { value: "673", code: "BN", text: "Brunei" },
  { value: "359", code: "BG", text: "Bulgaria" },
  { value: "226", code: "BF", text: "Burkina Faso" },
  { value: "257", code: "BI", text: "Burundi" },
  { value: "855", code: "KH", text: "Cambodia" },
  { value: "237", code: "CM", text: "Cameroon" },
  { value: "1", code: "CA", text: "Canada" },
  { value: "238", code: "CV", text: "Cape Verde" },
  { value: "236", code: "CF", text: "Central African Republic" },
  { value: "235", code: "TD", text: "Chad" },
  { value: "56", code: "CL", text: "Chile" },
  { value: "86", code: "CN", text: "China" },
  { value: "61", code: "CX", text: "Christmas Island" },
  { value: "61", code: "CC", text: "Cocos Islands" },
  { value: "57", code: "CO", text: "Colombia" },
  { value: "269", code: "KM", text: "Comoros" },
  { value: "682", code: "CK", text: "Cook Islands" },
  { value: "506", code: "CR", text: "Costa Rica" },
  { value: "385", code: "HR", text: "Croatia" },
  { value: "53", code: "CU", text: "Cuba" },
  { value: "599", code: "CW", text: "Curacao" },
  { value: "357", code: "CY", text: "Cyprus" },
  { value: "420", code: "CZ", text: "Czech Republic" },
  { value: "243", code: "CD", text: "Democratic Republic Of The Congo" },
  { value: "45", code: "DK", text: "Denmark" },
  { value: "253", code: "DJ", text: "Djibouti" },
  { value: "670", code: "TL", text: "East Timor" },
  { value: "593", code: "EC", text: "Ecuador" },
  { value: "20", code: "EG", text: "Egypt" },
  { value: "503", code: "SV", text: "El Salvador" },
  { value: "240", code: "GQ", text: "Equatorial Guinea" },
  { value: "291", code: "ER", text: "Eritrea" },
  { value: "372", code: "EE", text: "Estonia" },
  { value: "251", code: "ET", text: "Ethiopia" },
  { value: "500", code: "FK", text: "Falkland Islands" },
  { value: "298", code: "FO", text: "Faroe Islands" },
  { value: "679", code: "FJ", text: "Fiji" },
  { value: "358", code: "FI", text: "Finland" },
  { value: "33", code: "FR", text: "France" },
  { value: "689", code: "PF", text: "French Polynesia" },
  { value: "241", code: "GA", text: "Gabon" },
  { value: "220", code: "GM", text: "Gambia" },
  { value: "995", code: "GE", text: "Georgia" },
  { value: "49", code: "DE", text: "Germany" },
  { value: "233", code: "GH", text: "Ghana" },
  { value: "350", code: "GI", text: "Gibraltar" },
  { value: "30", code: "GR", text: "Greece" },
  { value: "299", code: "GL", text: "Greenland" },
  { value: "502", code: "GT", text: "Guatemala" },
  { value: "224", code: "GN", text: "Guinea" },
  { value: "245", code: "GW", text: "Guinea-Bissau" },
  { value: "592", code: "GY", text: "Guyana" },
  { value: "509", code: "HT", text: "Haiti" },
  { value: "504", code: "HN", text: "Honduras" },
  { value: "852", code: "HK", text: "Hong Kong" },
  { value: "36", code: "HU", text: "Hungary" },
  { value: "354", code: "IS", text: "Iceland" },
  { value: "91", code: "IN", text: "India" },
  { value: "62", code: "ID", text: "Indonesia" },
  { value: "98", code: "IR", text: "Iran" },
  { value: "964", code: "IQ", text: "Iraq" },
  { value: "353", code: "IE", text: "Ireland" },
  { value: "972", code: "IL", text: "Israel" },
  { value: "39", code: "IT", text: "Italy" },
  { value: "225", code: "CI", text: "Ivory Coast" },
  { value: "81", code: "JP", text: "Japan" },
  { value: "962", code: "JO", text: "Jordan" },
  { value: "7", code: "KZ", text: "Kazakhstan" },
  { value: "254", code: "KE", text: "Kenya" },
  { value: "686", code: "KI", text: "Kiribati" },
  { value: "383", code: "XK", text: "Kosovo" },
  { value: "965", code: "KW", text: "Kuwait" },
  { value: "996", code: "KG", text: "Kyrgyzstan" },
  { value: "856", code: "LA", text: "Laos" },
  { value: "371", code: "LV", text: "Latvia" },
  { value: "961", code: "LB", text: "Lebanon" },
  { value: "266", code: "LS", text: "Lesotho" },
  { value: "231", code: "LR", text: "Liberia" },
  { value: "218", code: "LY", text: "Libya" },
  { value: "423", code: "LI", text: "Liechtenstein" },
  { value: "370", code: "LT", text: "Lithuania" },
  { value: "352", code: "LU", text: "Luxembourg" },
  { value: "853", code: "MO", text: "Macau" },
  { value: "389", code: "MK", text: "Macedonia" },
  { value: "261", code: "MG", text: "Madagascar" },
  { value: "265", code: "MW", text: "Malawi" },
  { value: "60", code: "MY", text: "Malaysia" },
  { value: "960", code: "MV", text: "Maldives" },
  { value: "223", code: "ML", text: "Mali" },
  { value: "356", code: "MT", text: "Malta" },
  { value: "692", code: "MH", text: "Marshall Islands" },
  { value: "222", code: "MR", text: "Mauritania" },
  { value: "230", code: "MU", text: "Mauritius" },
  { value: "262", code: "YT", text: "Mayotte" },
  { value: "52", code: "MX", text: "Mexico" },
  { value: "691", code: "FM", text: "Micronesia" },
  { value: "373", code: "MD", text: "Moldova" },
  { value: "377", code: "MC", text: "Monaco" },
  { value: "976", code: "MN", text: "Mongolia" },
  { value: "382", code: "ME", text: "Montenegro" },
  { value: "212", code: "MA", text: "Morocco" },
  { value: "258", code: "MZ", text: "Mozambique" },
  { value: "95", code: "MM", text: "Myanmar" },
  { value: "264", code: "NA", text: "Namibia" },
  { value: "674", code: "NR", text: "Nauru" },
  { value: "977", code: "NP", text: "Nepal" },
  { value: "31", code: "NL", text: "Netherlands" },
  { value: "599", code: "AN", text: "Netherlands Antilles" },
  { value: "687", code: "NC", text: "New Caledonia" },
  { value: "64", code: "NZ", text: "New Zealand" },
  { value: "505", code: "NI", text: "Nicaragua" },
  { value: "227", code: "NE", text: "Niger" },
  { value: "234", code: "NG", text: "Nigeria" },
  { value: "683", code: "NU", text: "Niue" },
  { value: "850", code: "KP", text: "North Korea" },
  { value: "47", code: "NO", text: "Norway" },
  { value: "968", code: "OM", text: "Oman" },
  { value: "92", code: "PK", text: "Pakistan" },
  { value: "680", code: "PW", text: "Palau" },
  { value: "970", code: "PS", text: "Palestine" },
  { value: "507", code: "PA", text: "Panama" },
  { value: "675", code: "PG", text: "Papua New Guinea" },
  { value: "595", code: "PY", text: "Paraguay" },
  { value: "51", code: "PE", text: "Peru" },
  { value: "63", code: "PH", text: "Philippines" },
  { value: "64", code: "PN", text: "Pitcairn" },
  { value: "48", code: "PL", text: "Poland" },
  { value: "351", code: "PT", text: "Portugal" },
  { value: "974", code: "QA", text: "Qatar" },
  { value: "242", code: "CG", text: "Republic Of The Congo" },
  { value: "262", code: "RE", text: "Reunion" },
  { value: "40", code: "RO", text: "Romania" },
  { value: "7", code: "RU", text: "Russia" },
  { value: "250", code: "RW", text: "Rwanda" },
  { value: "590", code: "BL", text: "Saint Barthelemy" },
  { value: "290", code: "SH", text: "Saint Helena" },
  { value: "590", code: "MF", text: "Saint Martin" },
  { value: "508", code: "PM", text: "Saint Pierre And Miquelon" },
  { value: "685", code: "WS", text: "Samoa" },
  { value: "378", code: "SM", text: "San Marino" },
  { value: "239", code: "ST", text: "Sao Tome And Principe" },
  { value: "966", code: "SA", text: "Saudi Arabia" },
  { value: "221", code: "SN", text: "Senegal" },
  { value: "381", code: "RS", text: "Serbia" },
  { value: "248", code: "SC", text: "Seychelles" },
  { value: "232", code: "SL", text: "Sierra Leone" },
  { value: "65", code: "SG", text: "Singapore" },
  { value: "421", code: "SK", text: "Slovakia" },
  { value: "386", code: "SI", text: "Slovenia" },
  { value: "677", code: "SB", text: "Solomon Islands" },
  { value: "252", code: "SO", text: "Somalia" },
  { value: "27", code: "ZA", text: "South Africa" },
  { value: "82", code: "KR", text: "South Korea" },
  { value: "211", code: "SS", text: "South Sudan" },
  { value: "34", code: "ES", text: "Spain" },
  { value: "94", code: "LK", text: "Sri Lanka" },
  { value: "249", code: "SD", text: "Sudan" },
  { value: "597", code: "SR", text: "Suriname" },
  { value: "47", code: "SJ", text: "Svalbard And Jan Mayen" },
  { value: "268", code: "SZ", text: "Swaziland" },
  { value: "46", code: "SE", text: "Sweden" },
  { value: "41", code: "CH", text: "Switzerland" },
  { value: "963", code: "SY", text: "Syria" },
  { value: "886", code: "TW", text: "Taiwan" },
  { value: "992", code: "TJ", text: "Tajikistan" },
  { value: "255", code: "TZ", text: "Tanzania" },
  { value: "66", code: "TH", text: "Thailand" },
  { value: "228", code: "TG", text: "Togo" },
  { value: "690", code: "TK", text: "Tokelau" },
  { value: "676", code: "TO", text: "Tonga" },
  { value: "216", code: "TN", text: "Tunisia" },
  { value: "90", code: "TR", text: "Turkey" },
  { value: "993", code: "TM", text: "Turkmenistan" },
  { value: "688", code: "TV", text: "Tuvalu" },
  { value: "256", code: "UG", text: "Uganda" },
  { value: "380", code: "UA", text: "Ukraine" },
  { value: "971", code: "AE", text: "United Arab Emirates" },
  { value: "44", code: "GB", text: "United Kingdom" },
  { value: "1", code: "US", text: "United States" },
  { value: "598", code: "UY", text: "Uruguay" },
  { value: "998", code: "UZ", text: "Uzbekistan" },
  { value: "678", code: "VU", text: "Vanuatu" },
  { value: "379", code: "VA", text: "Vatican" },
  { value: "58", code: "VE", text: "Venezuela" },
  { value: "84", code: "VN", text: "Vietnam" },
  { value: "681", code: "WF", text: "Wallis And Futuna" },
  { value: "212", code: "EH", text: "Western Sahara" },
  { value: "967", code: "YE", text: "Yemen" },
  { value: "260", code: "ZM", text: "Zambia" },
  { value: "263", code: "ZW", text: "Zimbabwe" }
];
export default { API_URL, reCaptcha, web_server, country };
