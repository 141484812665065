import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGIN_SSO = "LoginSSO";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  /**
   * ---------------------------------------------------
   * params credential username(email) & password
   * ---------------------------------------------------
   */
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setIntercept();
      ApiService.post("/auth/login/", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_ERROR, [error.message]);
          reject(error);
        });
    });
  },

  [LOGIN_SSO](context, token) {
    return new Promise(resolve => {
      context.commit(SET_AUTH, { token: token });
      resolve(true);
    });
  },

  /**
   * -------------------------------------------------
   * logout proses
   * -------------------------------------------------
   */
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  /**
   * --------------------------------------------------
   * verifikasi token digunakan di main.js
   * --------------------------------------------------
   */
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/auth/verify").catch(({ response }) => {
        if (response) {
          if (response.status === 401) {
            context.commit(PURGE_AUTH);
            location.reload(); // refresh untuk ke login

            // this.$router.push({name:"login"});
            // masih belum tahu cara redirect ke login menggunakan route di file ini
            // tidak bisa mneggunakan this.$router.push({name:"login"});
          }
          context.commit(SET_ERROR, response.data.message);
        } else {
          context.commit(PURGE_AUTH);
        }
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
